<template>
  <div class="vc-pane flex justify-between items-center gap-4" :class="{ 'w-full': mobile }">
    <div v-for="value in [1, 2, 3]" :key="value" class="flex-1 flex" :class="classes[value - 1]">
      <WebccButton :variant="flexTrip.fuzzyness === value ? 'grey' : 'white'" outline class="btn-responsive text-center" @click="flexTrip.fuzzyness = value">
        &plusmn; {{ $t(`${trPrefix}night_s`, {}, value) }}
      </WebccButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const classes = ['justify-start', 'justify-center', 'justify-end']
const trPrefix = 'www.components.elements.FlexibleDates.'
const mobile = computed(() => useScreen().isExtraSmall)
const flexTrip = useFlexTrip()
</script>

<style scoped>
.vc-pane {
  flex-wrap: wrap;
}

.btn-responsive {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
