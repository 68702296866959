import pick from 'lodash-es/pick'

const SEARCH_API_ALLOWED_PARAMS = Object.freeze<SearchApiParams>({
  language: undefined,
  currency: undefined,
  season: undefined,
  page: undefined,
  pagesize: undefined,
  sorting: undefined,
  brand: undefined,
  salesoffice: undefined,
  q: undefined,
  checkin: undefined,
  checkout: undefined,
  duration: undefined,
  country: undefined,
  region: undefined,
  place: undefined,
  code: undefined,
  geo: undefined,
  n: undefined,
  w: undefined,
  s: undefined,
  e: undefined,
  zoom: undefined,
  lat: undefined,
  lon: undefined,
  radius: undefined,
  pax: undefined,
  pets: undefined,
  house: undefined,
  apartment: undefined,
  villa: undefined,
  chalet: undefined,
  farmhouse: undefined,
  detached: undefined,
  stars: undefined,
  rating: undefined,
  reviews: undefined,
  bedrooms: undefined,
  bathrooms: undefined,
  sea: undefined,
  lake: undefined,
  ski: undefined,
  center: undefined,
  wlan: undefined,
  aircondition: undefined,
  parking: undefined,
  garage: undefined,
  'balcony-or-terrace': undefined,
  dishwasher: undefined,
  washingmachine: undefined,
  tv: undefined,
  sea_or_lake_view: undefined,
  bbq: undefined,
  boat: undefined,
  cots: undefined,
  hottub: undefined,
  fireplace: undefined,
  sauna: undefined,
  wheelchair: undefined,
  charging_station: undefined,
  pool: undefined,
  pool_private: undefined,
  pool_indoor: undefined,
  pool_children: undefined,
  'min-price': undefined,
  maxPrice: undefined,
  discount: undefined,
  special_offer: undefined,
  last_minute: undefined,
  early_booker: undefined,
  'discount-20': undefined,
  cheapcheap: undefined,
  familyfriendly: undefined,
  holiday_resort: undefined,
  residence: undefined,
  citytrips: undefined,
  utoring: undefined,
  casa: undefined,
  swiss_peak: undefined,
  workation: undefined,
  sustainable: undefined,
  skiing: undefined,
  hiking: undefined,
  golfing: undefined,
  cycling: undefined,
  wellness: undefined,
  tennis: undefined,
  surfing: undefined,
  sailing: undefined,
  mountainbiking: undefined,
  riding: undefined,
  crosscountryskiing: undefined,
  fishing: undefined,
  fishing_certified: undefined,
  studio: undefined,
  lso: undefined,
  bo: undefined,
  rooms: undefined,
  facet: undefined,
})

const SEARCH_API_PAGESIZE_DEFAULT = 20

export default function useSearchApi() {
  const baseURL = '/search-api/v1/engines/accomtrips'

  async function search(query: SearchApiParams) {
    return await useFetch<Result>('/', {
      baseURL,
      params: mapSearchApiParams(query),
      transform: (result) => setDocPosition(result, query),
    })
  }

  async function searchMulti(requests: Params[], language?: string) {
    return await useFetch<Result[]>('/multi', {
      baseURL,
      params: { request: requests.map(stringifyParams), language },
    })
  }

  return { search, searchMulti }
}

/**
 * Maps param values and leaves only those params that the new Search API can accept:
 * - converts date fields to server date format
 * - removes redundant params because otherwise the API will return "Bad Request"
 *
 * @param params Raw params.
 * @returns Only paras that the new Search API can accept.
 */
function mapSearchApiParams<T extends SearchApiParams>(params: T): SearchApiParams {
  const [checkin, checkout] = [params.checkin, params.checkout].map((date) => (date ? toServerDate(date) : undefined))

  return pick({ ...params, checkin, checkout }, Object.keys(SEARCH_API_ALLOWED_PARAMS))
}

/**
 * Converts params to a multi-search request string.
 *
 * @param params Request params.
 * @returns String in format `country:AT,pets:true,sorting:-averagerating`.
 */
function stringifyParams(params: Params): string {
  return JSON.stringify(params).replace(/["{}]/g, '')
}

/**
 * Filling in the global doc position - mainly used for tracking.
 * Not the best way and place for this, but at least centralized.
 *
 * @param result Search result
 * @param query Initial search query
 * @returns The same result object with filled in `position` in its `docs`
 */
function setDocPosition(result: Result, query: SearchApiParams) {
  const page = query.page ?? 1
  const pagesize = query.pagesize ?? SEARCH_API_PAGESIZE_DEFAULT

  result.docs.forEach((doc, index) => (doc.position = pagesize * (page - 1) + index + 1))

  return result
}
