const trPrefix = 'www.components.elements.FlexibleDates.'

export const useFlexTripStore = defineStore('flextrip', () => {
  const updateKey = ref('')
  const open = ref(false)
  const start = ref(tryParseDate(useParams().all.checkin?.toString()))
  const end = ref(tryParseDate(useParams().all.checkout?.toString()))
  const fuzzyness = ref(0)
  const flexibleCalendar = ref(useSessionStorage('flexible-search', !!useParams().all.duration).value && ![1, 2, 3].includes(fuzzyness.value)) // use flexible calendar tab when true
  const flexDuration = ref(tryParseInt(useParams().all.duration?.toString()) || flexibleCalendar.value ? DURATION_DEFAULT : null)
  // i18n plugin is initialised only after stores
  const i18n = useI18n()

  const range = computed<DateRange>({
    get() {
      return { start: start.value, end: end.value }
    },
    set(r) {
      start.value = r.start
      end.value = r.end
      if (!flexibleSearch.value) open.value = false
    },
  })

  const valid = computed(() => isDateRangeValid(range.value) && !!getDateRangeInDays(range.value))

  const flexibleSearch = computed(() => flexibleCalendar.value || useFlag('flexible-date-search').value)
  const duration = computed(() => (flexibleCalendar.value || fuzzyness.value > 0 ? flexDuration.value : undefined))
  const durationText = computed(() => (duration.value ? getDurationText(duration.value) : ''))
  const fuzzynessText = computed(() => (fuzzyness.value > 0 ? ` ± ${i18n.t(`${trPrefix}night_s`, {}, fuzzyness.value)}` : ''))

  const filter = computed(() =>
    start.value && end.value
      ? { checkin: toDate(start.value), checkout: toDate(end.value), duration: duration.value }
      : { checkin: undefined, checkout: undefined, duration: undefined },
  )

  const tripText = computed(() => {
    const { start: rangeStart, end: rangeEnd } = range.value

    if (flexibleCalendar.value && valid.value) {
      return i18n.t(`${trPrefix}night_between`, {
        duration: durationText.value,
        start: formatDatetime(toDate(rangeStart!), '2-digit-short'),
        end: formatDatetime(toDate(rangeEnd!), '2-digit-short'),
      })
    }

    if (rangeStart) {
      return [rangeStart, rangeEnd].map((date) => (date ? formatDatetime(toDate(date), undefined) : '')).join(' - ') + fuzzynessText.value
    }

    return ''
  })

  function reset() {
    start.value = undefined
    end.value = undefined
    fuzzyness.value = 0
    flexDuration.value = DURATION_DEFAULT
    updateKey.value = new Date().toISOString()
  }

  function changeDuration(d: number) {
    flexDuration.value = d
    if (start.value && end.value && d > calculateDuration(start.value, end.value)) {
      end.value = daysAdd(start.value, d)
    }
  }

  function getDurationText(duration: number) {
    return duration % 7 ? i18n.t(`${trPrefix}night_s`, duration) : i18n.t(`${trPrefix}week_s`, duration / 7)
  }

  return {
    flexibleCalendar,
    flexibleSearch,
    open,
    range,
    updateKey,
    valid,
    duration,
    filter,
    durationText,
    tripText,
    reset,
    changeDuration,
    getDurationText,
    fuzzyness,
    fuzzynessText,
    flexDuration,
  }
})
