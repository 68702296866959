import omitBy from 'lodash-es/omitBy'
import isUndefined from 'lodash-es/isUndefined'
import pickBy from 'lodash-es/pickBy'
import { getQuery } from 'h3'
import { getQuerySettingsParams } from './getters/settings-params'
import { getQueryClickIDParams } from './getters/click-id-params'
import { getQueryRedirectParams } from './getters/redirect-params'
import { getQueryABParams } from './getters/ab-test-params'
import { getQueryPartnerIdParams } from './getters/partners-params'
import { getQueryCNASParams } from './getters/cnas-params'
import { getQueryCurrencyParams } from './getters/currency-params'
import { getQueryJWTParams } from './getters/jwt-params'
import { getQueryIFrameParams } from './getters/iframe-params'
import { getQueryLegacyParams } from './getters/legacy-params'
import { getQueryTripParams } from './getters/trip-params'
import { getQueryCoordinatesParams } from './getters/coordinates-params'
import type { PlainParams, Params } from './params.types'

export default defineNuxtPlugin({
  name: 'app:params',
  enforce: 'pre',
  setup(nuxtApp) {
    // Validated (external) GET parameters: are only evaluated server-side and not modified afterward
    const ssrParams = useState<PlainParams>('ssr-params', () => {
      return import.meta.server ? unpack(getServerSideParams(getQuery(nuxtApp.ssrContext!.event))) : {}
    })
    // Persisted params are outside of this module, e.g. PartnerID
    const persisted = reactive(new Map<string, unknown>())
    const persistent = computed(() =>
      unpack(Object.fromEntries(Object.assign(Object.entries(ssrParams.value), persisted.entries()).filter(([key]) => key !== 'redirect'))),
    )
    const iframe = computed(() => (typeof ssrParams.value.iframe === 'object' ? ({ ...ssrParams.value.iframe } as unknown as IFrameParams) : undefined))
    const all = ref<PlainParams>({})

    const currency = computed(() => all.value.currency?.toString())

    function init(params: Params) {
      all.value = { ...persistent.value, ...unpack(params) }
    }

    const $params = reactive({
      persistent,
      redirect: ssrParams,
      iframe,
      all,
      currency,
      persist<V = unknown>(name: string, value: V) {
        persisted.set(name, value)
      },
      _init<Q extends Record<string, unknown>>(rawQuery: Q) {
        init(getUnifiedParams(rawQuery))
      },
    })

    nuxtApp.provide('params', $params)
  },
})

function getServerSideParams<Q extends Record<string, unknown>>(query: Q) {
  return omitBy(
    {
      ...getQueryABParams(query),
      ...getQuerySettingsParams(query),
      ...getQueryJWTParams(query),
      ...getQueryCNASParams(query),
      ...getQueryCurrencyParams(query),
      ...getQueryClickIDParams(query),
      ...getQueryRedirectParams(query),
      ...getQueryPartnerIdParams(query),
      ...getQueryIFrameParams(query),
    },
    isUndefined,
  ) as PlainParams
}

function getUnifiedParams<Q extends Record<string, unknown>>(rawQuery: Q) {
  const query = { ...rawQuery, ...getQueryLegacyParams(rawQuery) }

  const parsedParams: Params = {
    ...getQueryTripParams(query),
    ...getQueryCoordinatesParams(query),
    pax: tryParseInt(query.pax?.toString()),
    season: query.season?.toString() as Season,
  }

  const otherParams = Object.fromEntries(
    Object.entries(query)
      .filter(([param]) => param in ALLOWED_QUERY_PARAMS && !(param in parsedParams))
      .map(([param, value]) => [param, value === 'true' ? true : value?.toString()]),
  )

  return pickBy({ ...parsedParams, ...otherParams }, Boolean) as Params
}
