<template>
  <div class="relative" @click.stop>
    <client-only>
      <WebccDaterangeSearch
        v-if="flexTrip.open"
        ref="dateRangeSearch"
        :class="{ 'left-0 md:w-[60vw] md:max-w-3xl': flexTrip.flexibleSearch }"
        @close="flexTrip.open = false"
      />
    </client-only>

    <div class="space-y-1">
      <span v-if="flexTrip.flexibleSearch && !flexTrip.fuzzyness" class="mb-2 text-xs text-txt-weak">{{ flexTrip.tripText }}</span>
      <div class="relative w-full">
        <WebccIcon name="site/calendar" class="icon pointer-events-none absolute mx-2 h-full w-4 text-txt opacity-60" aria-hidden="true" />
        <input
          :placeholder="$t(`${trPrefix}checkin`)"
          readonly
          class="input w-full rounded border-0 border-bgr-300 bg-bgr py-1.5 pl-10 pr-4 text-sm text-txt shadow focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none active:bg-bgr-50"
          :value="useSearch().filters.checkin ? formatDatetime(useSearch().filters.checkin!, undefined, timezone) + flexTrip.fuzzynessText : undefined"
          @click.stop="openFocusCalendar"
          @focus="openFocusCalendar"
        />
        <WebccIcon
          v-if="useSearch().filters.checkin && !(useSearch().filters.checkin === useSearch().filters.checkout)"
          name="site/x"
          class="icon absolute right-0 top-0 mx-2 h-full w-4 cursor-pointer fill-current text-txt opacity-60"
          @click="clearTrip()"
        />
      </div>
      <div class="relative w-full">
        <WebccIcon name="site/calendar" class="icon pointer-events-none absolute mx-2 h-full w-4 text-txt opacity-60" aria-hidden="true" />
        <input
          :placeholder="$t(`${trPrefix}checkout`)"
          readonly
          class="input w-full rounded border-0 border-bgr-300 bg-bgr py-1.5 pl-10 pr-4 text-sm text-txt shadow focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none active:bg-bgr-50"
          :value="useSearch().filters.checkout ? formatDatetime(useSearch().filters.checkout!, undefined, timezone) + flexTrip.fuzzynessText : undefined"
          @click.stop="openFocusCalendar"
          @focus="flexTrip.open = false"
        />
        <WebccIcon
          v-if="useSearch().filters.checkin && !(useSearch().filters.checkin === useSearch().filters.checkout)"
          name="site/x"
          class="icon absolute right-0 top-0 mx-2 h-full w-4 cursor-pointer fill-current text-txt opacity-60"
          @click="clearTrip()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const trPrefix = 'www.components.views.search.filter.'

const dateRangeSearch = ref()

const flexTrip = useFlexTrip()

useToggler().register('datepicker')

async function openFocusCalendar() {
  if (flexTrip.open) return

  flexTrip.open = true
  await nextTick()
  ;(document.activeElement as HTMLInputElement)?.blur()
  dateRangeSearch.value?.focusOnFirstDate()
}

watch(
  () => flexTrip.fuzzyness,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      useSearch().setFilter({ duration: useSearch().filters.duration as number })
    }
  },
)

function clearTrip() {
  flexTrip.reset()
  useSearch().setFilter({ checkin: undefined, checkout: undefined, duration: 7 })
}
</script>
