<template>
  <article class="relative overflow-hidden rounded bg-bgr shadow">
    <WebccLoaderOverlay v-if="loadTranslation" size="sm" absolute />
    <header class="flex items-center justify-between bg-bgr-200 px-4 py-2">
      <RatingHearts :rating="review.rating" color="theme" class="flex-grow md:flex-grow-0" />

      <span class="hidden flex-grow pl-3 text-base font-medium md:block">{{ customizedReview?.title }}</span>

      <span class="mr-4">{{ review.customer.firstname }} {{ review.customer.lastname }}</span>

      <WebccIcon filled :name="'flags/' + review.customer.country.toLowerCase()" class="mr-2 h-6 w-6" />

      <button aria-label="more" @click="toggleDetails()">
        <WebccIcon filled :name="`site/chevron-${showDetails ? 'up' : 'down'}`" class="h-6 w-6 stroke-1" />
      </button>
    </header>

    <transition v-if="hasReviewDetails" name="fade">
      <ul v-show="showDetails" class="list-none bg-bgr-200 p-3">
        <li v-if="review.detailRating.generalPropertyCondition" class="flex items-center p-1">
          <RatingHearts :rating="review.detailRating.generalPropertyCondition" />
          <span class="pl-2">{{ $t(`${trPrefix}condition`) }}</span>
        </li>
        <li v-if="review.detailRating.cleanlinessInGeneral" class="flex items-center p-1">
          <RatingHearts :rating="review.detailRating.cleanlinessInGeneral" class="text-thm" />
          <span class="pl-2">{{ $t(`${trPrefix}cleanliness`) }}</span>
        </li>
        <li v-if="review.detailRating.customerServiceInGeneral" class="flex items-center p-1">
          <RatingHearts :rating="review.detailRating.customerServiceInGeneral" class="text-thm" />
          <span class="pl-2">{{ $t(`${trPrefix}service`) }}</span>
        </li>
      </ul>
    </transition>

    <!-- { "title": "Hervorragend ", "rating": 5, "recommended": true, "travelWith": "Friends", "customer": { "salutation": "Herr", "firstname": "Dietmar",
    "lastname": "R.", "country": "DE", "language": "DE" }, "detailRating": { "generalPropertyCondition": 5, "cleanlinessInGeneral": 5,
    "customerServiceInGeneral": 5 }, "published": "2021-09-21", "arrivalDate": "2021-09-11", "departureDate": "2021-09-18", "text": "Eine vom
    Preis-Leistungsverhältnis hervorragende Unterkunft ", "tips": "", "replyText": "", "language": "de" } -->

    <main class="prose prose-sm prose-neutral max-w-none p-3">
      <WebccButton v-if="displayTranslationButton" size="sm" class="float-right mb-2 ml-2" :title="$t(`${trPrefix}byDeepl`)" @click="toggleTranslation">
        <span>{{ $t(`${trPrefix}${showTranslation ? 'backToOriginal' : 'translate'}`) }}</span>
      </WebccButton>
      {{ customizedReview?.text }}
      <template v-if="showTips && showDetails && review.tips">
        <h5 class="pt-4 text-base font-bold">
          <span>{{ $t(`${trPrefix}travelTips`) }}</span
          >:
        </h5>
        <p>{{ customizedReview?.tips }}</p>
      </template>
      <div v-if="customizedReview?.replyText" class="py-3 pl-12 pr-6" style="padding: 0.8em 1.5em 0.8em 3em">
        <h4 class="mb-2 text-sm text-thm">{{ $t(`${trPrefix}response`, { company }) }}</h4>
        {{ customizedReview.replyText }}
      </div>
    </main>

    <footer class="py-1 text-center text-sm text-txt">
      <span>{{ $t(`${trPrefix}reviewDate`, { date: reviewDate }) }}</span>
      /
      <span v-if="travelWith">{{ ($t(`${trPrefix}stayWith`), { with: travelWith, month: month }) }}</span>
      <span v-else>{{ $t(`${trPrefix}stay`, { month }) }}</span>
    </footer>
  </article>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.reviews.Item.'

const { t } = useI18n()

interface Props {
  review: Review
  showNavigation?: boolean
  locale?: string
  company?: string
}

const props = withDefaults(defineProps<Props>(), {
  showNavigation: true,
  locale: 'de',
  company: 'Interhome',
})

const showDetails: Ref<boolean> = ref(false)
const showTips: Ref<boolean> = ref(true)
const showTranslation: Ref<boolean> = ref(false)
const translatedReview: Ref<ReviewTranslation | null> = ref(null)
const translationDone: Ref<boolean> = ref(false)
const loadTranslation: Ref<boolean> = ref(false)
const { language } = useL10N()

const customizedReview = computed(() => {
  if (showTranslation.value && translationDone.value) {
    return {
      text: translatedReview.value?.body.text,
      tips: translatedReview.value?.tips.text,
      replyText: translatedReview.value?.reply.text,
      title: translatedReview.value?.title.text,
    }
  }
  return { text: props.review?.text, tips: props.review?.tips, replyText: props.review?.replyText, title: props.review?.title }
})

const travelWith = computed(() => {
  return props.review.travelWith ? t(`${trPrefix}${props.review.travelWith}` as TranslationKey) : ''
})

// const flagIcon = computed(() => {
//   return props.review.reviewCountry ? '/static/svg/flags.svg#' : '' // props.review.reviewCountry.toLowerCase() : ''
// })

const month = computed(() => {
  const date = new Date(props.review.arrivalDate)
  return `${date.toLocaleString(props.locale, { month: 'long' })} ${date.getFullYear()}`
})

const hasReviewDetails = computed(() => {
  return (
    !!props.review.detailRating.generalPropertyCondition ||
    !!props.review.detailRating.cleanlinessInGeneral ||
    !!props.review.detailRating.customerServiceInGeneral
  )
})

const reviewDate = computed(() => {
  return new Date(props.review.published).toLocaleString(props.locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
})

const displayTranslationButton = computed(() => {
  // Translation nur, wenn title oder text vorhanden -> diesen Check auf jeden Fall beibehalten
  // review.language allein leider nicht verlässlich, deshalb Prüfung auf mehrere Kriterien -> bleibt aber Restunsicherheit, ggf. verzichten
  return !!(
    (props.review.title || props.review.text) &&
    // // ggf. auf die folgenden Checks verzichten
    (props.review.language.toLowerCase() !== language.toLowerCase() || props.review.customer.language.toLowerCase() !== language.toLowerCase())
  )
})

function toggleDetails() {
  if (showDetails.value) {
    useTracking().handlers?.common.reviewsDetailsClosed()
  } else {
    useTracking().handlers?.common.reviewsDetailsOpened()
  }
  showDetails.value = !showDetails.value
}

function toggleTranslation() {
  if (showTranslation.value) {
    useTracking().handlers?.common.reviewsHideTranslation()
  } else {
    useTracking().handlers?.common.reviewsShowTranslation()
  }
  showTranslation.value = !showTranslation.value
  if (translationDone.value === false) {
    translateReview()
  }
}

async function translateReview() {
  loadTranslation.value = true
  const targetlang = language
  const obj = {
    title: props.review.title.replace(/\r?\n?/g, ''),
    body: props.review.text.replace(/\r?\n?/g, ''),
    tips: props.review.tips?.replace(/\r?\n?/g, '') || '',
    targetlang,
    reply: props.review.replyText?.replace(/'/g, ' ') || '',
  }
  try {
    translatedReview.value = await useReviews().translate(obj)
    translationDone.value = true
  } catch (error) {
    console.error(error)
  } finally {
    loadTranslation.value = false
  }
}
</script>
