<template>
  <div />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.notifications.'

const { t } = useI18n()
const params = useParams()
const toaster = useToaster()
const user = useUser()

onMounted(() => {
  if (useAB().isActive('guestWorld') && params.all.signedIn) notifySignedIn()
})

function notifySignedIn() {
  if (!user.signedIn) return

  toaster.success(`${t(`${trPrefix}signedIn.text`)} <strong>${user.email}</strong>`, { delay: 300 })
}
</script>
