export default {
  whyElementImpression,
  footerElementImpression,
  footerChangeCountryAndLanguage,
  footerSocialMediaLinkElementClick,
  newsletterBannerImpression,
  newsletterBannerSubscribe,
  safetyInformationsImpression,
  safetyInformationsClick,
  headerLogoClick,
  headerContactUsClick,
  headerWishlistClick,
  headerSelectLanguage,
  headerSiteNavigation,
  topRegionTeaserElementImpression,
  topRegionTeaserElementClick,
  popularDestinationLinksImpression,
  popularDestinationLinksClick,
  uniqueSellingPointsImpression,
  autosuggestItemSelected,
  datesArrivalSelect,
  datesDurationSelect,
  numberPax,
  submitSearch,
  rentalSpecialistImpression,
  homeOwnerTeaserImpression,
  homeOwnerTeaserClick,
  topCategorySliderDisplayed,
  topCategorySliderView,
  topCategorySliderNext,
  topCategorySliderPrevious,
  displayed,
  addedToWishlist,
  removedFromWishlist,
  viewed,
  scrolled,
}

function homePageTracking(event: string, action: string, label: string, component: string, position?: string, category?: string) {
  useTracking().send({
    event,
    action,
    label,
    component,
    position,
    category,
    language: useL10N().language,
    _clear: true,
  })
}

function whyElementImpression({ params }: { params: { pos: string } }) {
  homePageTracking('why_interhome', 'displayed', 'Why Interhome', 'why-interhome', params.pos)
}

function footerElementImpression({ params }: { params: { pos: string } }) {
  homePageTracking('footer', 'displayed', 'Footer', 'footer', params.pos)
}

function footerChangeCountryAndLanguage(country: string, pos: string) {
  homePageTracking('footer', 'change country and language', country, 'footer', pos)
}

function footerSocialMediaLinkElementClick(media: string, pos: string) {
  homePageTracking('footer', 'view', media, 'footer', pos)
}

function newsletterBannerImpression() {
  homePageTracking('newsletter_banner', 'displayed', 'Newsletter Banner', 'newsletter', 'homepage')
}

function newsletterBannerSubscribe(email: string) {
  homePageTracking('newsletter_banner', 'subscribe', email, 'newsletter', 'homepage')
}

function safetyInformationsImpression({ params }: { params: { pos: string } }) {
  homePageTracking('safety_informations', 'displayed', 'Safety Informations', 'safety-informations', params.pos)
}

function safetyInformationsClick(pos: string) {
  homePageTracking('safety_informations', 'view', 'More Info', 'safety-informations', pos)
}

//new header events, can be moved to 07-header when the old events are deleted

function headerLogoClick() {
  useTracking().send({
    event: 'header_elements',
    action: 'view',
    label: 'Homepage',
    category: undefined,
    component: 'header-link',
    position: 'header',
    language: useL10N().language,
    _clear: true,
  })
}

function headerContactUsClick() {
  useTracking().send({
    event: 'header_elements',
    action: 'view',
    label: 'Contact Us',
    category: undefined,
    component: 'header-link',
    position: 'header',
    language: useL10N().language,
    _clear: true,
  })
}

function headerWishlistClick() {
  useTracking().send({
    event: 'header_elements',
    action: 'view',
    label: 'Wishlist',
    category: undefined,
    component: 'header-link',
    position: 'header',
    language: useL10N().language,
    _clear: true,
  })
}

function headerSelectLanguage(select: string) {
  useTracking().send({
    event: 'header_elements',
    action: 'change language',
    label: select,
    category: undefined,
    component: 'header-link',
    position: 'header',
    language: useL10N().language,
    _clear: true,
  })
}

function headerSiteNavigation(navigation: string) {
  useTracking().send({
    event: 'header_elements',
    action: 'change language',
    label: navigation,
    category: undefined,
    component: 'header-link',
    position: 'header',
    language: useL10N().language,
    _clear: true,
  })
}

// end new header

//teaserstart

function topRegionTeaserElementImpression({ params }: { params: { region: string; slot: string } }) {
  //Will be removed
  useTracking().handlers?.teaser.topRegionTeaserElementImpression({ params: { id: 'Home Teaser 3', creative: '', position: params.slot } })
  useTracking().send({
    event: 'view_promotion',
    ecommerce: {
      creative_name: params.region,
      creative_slot: params.slot,
      promotion_id: 'Home Teaser 3',
      promotion_name: 'Top Regions',
    },
  })
}

function topRegionTeaserElementClick(region: string, slot: string) {
  useTracking().send({
    event: 'select_promotion',
    ecommerce: {
      creative_name: region,
      creative_slot: slot,
      promotion_id: 'Home Teaser 3',
      promotion_name: 'Top Regions',
    },
  })
}

//teaserend

function popularDestinationLinksImpression({ params }: { params: { pos: string } }) {
  homePageTracking('popular_destination_links', 'displayed', 'Popular Destination Links', 'popular-destination-links', params.pos)
}

function popularDestinationLinksClick(destination: string, pos: string) {
  homePageTracking('popular_destination_links', 'open', destination, 'popular-destination-links', pos)
}

function uniqueSellingPointsImpression({ params }: { params: { pos: string } }) {
  homePageTracking('unique_selling_points', 'displayed', 'Unique Selling Points', 'unique-selling-points', params.pos)
}

// quicksearch start, can be moved 09-quicksearch when deleting old events
function autosuggestItemSelected(item: AutosuggestItem) {
  const label = item.label + (item.levelLabel ? ` / ${item.levelLabel}` : '')

  useTracking().send({
    event: 'quicksearch',
    action: 'select',
    label,
    category: 'destination',
    component: 'quick-search',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function datesArrivalSelect(date: Date) {
  useTracking().send({
    event: 'quicksearch',
    action: 'select',
    label: toDateFormat(date, 'yyyy_MM_dd'),
    category: 'start date',
    component: 'quick-search',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function datesDurationSelect(duration: number) {
  useTracking().send({
    event: 'quicksearch',
    action: 'select',
    label: duration,
    category: 'duration',
    component: 'quick-search',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function numberPax(number: string) {
  useTracking().send({
    event: 'quicksearch',
    action: 'select',
    label: number,
    category: 'duration',
    component: 'quick-search',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function submitSearch({ destination, date, duration, pax }: { destination?: string; date?: string; duration?: string | number; pax?: number }) {
  useTracking().send({
    event: 'quicksearch',
    action: 'search',
    label: undefined,
    category: undefined,
    component: 'quick-search',
    position: undefined,
    language: useL10N().language,
    qs_destination: destination,
    qs_start_date: date,
    qs_duration: duration,
    qs_pax: pax,
    _clear: true,
  })
}
// quicksearch end

function rentalSpecialistImpression() {
  homePageTracking('hh_rental_specialist', 'displayed', 'Holiday home rental specialist', 'hh-rental-specialist')
}

function homeOwnerTeaserImpression() {
  homePageTracking('home_owner_teaser', 'displayed', 'Home Owner Teaser', 'home-owner-teaser')
}

function homeOwnerTeaserClick() {
  homePageTracking('home_owner_teaser', 'view', 'Start successfully renting out now', 'home-owner-teaser')
}

function topCategorySliderTracking(action: string, topics: Record<string, string>[]) {
  useTracking().send({
    event: 'top_categories_slider',
    action,
    label: 'Top Categories Slider',
    category: undefined,
    component: 'top-categories-slider',
    position: undefined,
    language: useL10N().language,
    topics,
    _clear: true,
  })
}

function topCategorySliderDisplayed(topics: Record<string, string>[]) {
  topCategorySliderTracking('displayed', topics)
}

function topCategorySliderView(topics: Record<string, string>[]) {
  topCategorySliderTracking('view', topics)
}

function topCategorySliderNext(topics: Record<string, string>[]) {
  topCategorySliderTracking('next', topics)
}

function topCategorySliderPrevious(topics: Record<string, string>[]) {
  topCategorySliderTracking('previous', topics)
}

function createRecommendationEvent(action: string, language: string, data?: PreparedItem[]): TrackingElement {
  return {
    event: 'universal_recommender',
    action,
    label: 'Universal Recommender',
    category: undefined,
    component: 'universal-recommender-home',
    position: undefined,
    language,
    items: data?.map((r) => r.accom),
    _clear: true,
  }
}

function displayed(language: string, data: PreparedItem[]) {
  useTracking().send(createRecommendationEvent('displayed', language, data))
}

function addedToWishlist(language: string, data: PreparedItem[]) {
  useTracking().send(createRecommendationEvent('add to wishlist', language, data))
}

function removedFromWishlist(language: string, data: PreparedItem[]) {
  useTracking().send(createRecommendationEvent('remove from wishlist', language, data))
}

function viewed(language: string, data: PreparedItem[]) {
  useTracking().send(createRecommendationEvent('view accommodation', language, data))
}

function scrolled(direction: ScrollDirectionHorizontal, language: string, data: PreparedItem[]) {
  const action = `${direction === 'left' ? 'previous' : 'next'} accommodation`
  useTracking().send(createRecommendationEvent(action, language, data))
}
